import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { DASHBOARD_PATH } from '@anchorage/frontoffice/utils/routes';

// Root page that should only be rendererd in local env
export default function Index() {
  const router = useRouter();
  useEffect(() => {
    router.push(DASHBOARD_PATH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>Redirecting to {DASHBOARD_PATH}</>;
}
